<template lang="pug">
div
  .content-loader-center.m-0.h-100(v-if='isLoadingCustomFieldTemplate || isCreatingCustomFieldTemplate || isUpdatingCustomFieldTemplate')
    .text-center.flex-center
      .loading-bg-inner 
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | {{ isLoadingCustomFieldTemplate ? 'Chargement du formulaire de champs personnalisés' : 'Enregistrement du formulaire de champs personnalisés...' }}
  .h-100(v-else)
    b-row.m-0.justify-content-center
      b-col.content-header(cols='9')
        .d-flex.align-items-start.w-100.justify-content-between
          .d-flex
            h2.content-header-title.float-left.m-0.pr-1.mr-1(style="border-right: 1px solid #e2e2e2")
              | Formulaire
            .content-header-search.mr-1
              h3.m-0(style="text-transform: capitalize;")
                | {{  dataForm.label }} 
                span.ml-50(v-show="archive" class="text-danger") (archive)
          .content-header-actions.d-flex
            feather-icon.cursor(icon='XIcon' size='24' @click='cancel()')
    b-row.m-0.justify-content-center(style="height: calc(100% - 110px)")
      b-col.h-100(cols='9')
        div.h-100(:id="'formEditCustomFieldTemplate'+key" style="overflow-x: hidden")
            b-row.my-0
                b-col.py-0
                    b-form-group.mb-1(label='Nom du formulaire *' label-for='label')
                        ejs-textbox(id="label" v-model='dataForm.label')
            b-row.my-0
                b-col.py-0
                    b-form-group.mb-1(label='Type du formulaire *' label-for='type')
                        ejs-dropdownlist(id="type" v-model='dataForm.type' :dataSource="customFieldTemplateType" :fields="{text:'label',value:'value'}" :enabled="!dataForm.id")
            vs-divider.mb-50.mt-1(color='#cccccc')
            div(v-for="(group, index) of dataForm.customFieldGroups" :key="index")
                b-row.my-0
                    b-col.py-0.mb-50.w-100.d-flex.justify-content-between.align-items-center 
                        div.d-flex.align-items-center
                            ejs-colorpicker(:id="'groupLabel'+index" v-model="group.color" mode="Palette")
                            h4.mb-0.ml-1 
                                ejs-inplaceeditor(:id="'groupLabel'+index" type="Text" v-model="group.label" :value="group.label" mode="Inline")
                        div
                            ejs-button(v-on:click.native="addField(index)" ref="fieldButton" content="+ Nouveau champs" :isPrimary="true" locale='fr' :cssClass="'e-outline e-small mr-1'")
                            ejs-button(v-on:click.native="addGroup(index)" ref="groupButton" content="+ Nouveau groupe" :isPrimary="true" locale='fr' :cssClass="'e-outline e-small mr-1'")
                            ejs-button(v-on:click.native="deleteGroup(index, group)" ref="deleteGroupButton" content="" :disabled="!index" iconCss='e-btn-sb-icons e-icon-trash'  locale='fr' :cssClass="'e-outline e-danger e-small'")
                b-row.my-0
                    b-col.py-0
                        .card.bg-light(style="box-shadow: none;")
                            b-row.m-0
                                b-col
                                    sync-grid.details(:ref="'overviewgrid'+index" :selectionSettings="{ type: 'Multiple' }" :gridId="'overviewgrid'+index" :dropOptions="{ targetID: 'overviewgrid'+index }" :allowRowDragAndDrop="true" :gridData="group.customFields" :name="name" :allowPaging="allowPaging" :headerData="headerData" :allowGrouping="allowGrouping" :tagguerButton="tagguerButton" :editSettings="editSettings" @actionBegin="actionBegin" @deleteButtonClicked="deleteButtonClicked")
        .flex.flex-column
          vs-divider.mb-50(color='#cccccc')
          b-row.my-0
              b-col.py-0(cols='12')
                  .d-flex.justify-content-between
                      div
                          ejs-button(v-on:click.native="cancel()" ref="cancelButton" content="Annuler" :isPrimary="true" locale='fr' :cssClass="'e-outline mr-1'")
                          ejs-button(v-on:click.native="archiveCustomFieldTemplateLocal(dataForm.id, dataForm.label)" ref="archiveButton" v-if="dataForm.id && !archive" content='' cssClass='e-outline e-danger m-0' iconCss='e-btn-sb-icons e-icon-archive'  :isPrimary="true" locale='fr')
                          ejs-button(v-on:click.native="deleteCustomFieldTemplateLocal(dataForm.id, dataForm.label)" ref="deleteButton" v-if="dataForm.id && archive" content='' cssClass='e-outline e-danger m-0' iconCss='e-btn-sb-icons e-icon-trash'  :isPrimary="true" locale='fr')
                          ejs-button(v-on:click.native="restoreCustomFieldTemplateLocal(dataForm.id, dataForm.label)" ref="restoreButton" v-if="dataForm.id && archive" content='' cssClass='e-outline e-warning m-0 ml-1' iconCss='e-btn-sb-icons e-icon-restore'  :isPrimary="true" locale='fr')
                      ejs-button(v-on:click.native="confirm()" ref="confirmButton" :content="dataForm.id ? 'Enregistrer' : 'Ajouter'" :isPrimary="true" locale='fr' :iconCss="'e-btn-sb-icons e-icons e-save'" iconPosition="Right")
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { ECivility } from "@/types/api-orisis/enums/enums";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { typeFields } from "@/types/api-orisis/enums/enums";
import { FormValidator } from "@syncfusion/ej2-vue-inputs";
import EditCustomField from "@/components/custom-field/EditCustomField.vue";
import FooterEditCustomField from "@/components/custom-field/FooterEditCustomField.vue";
import HeaderEditCustomField from "@/components/custom-field/HeaderEditCustomField.vue";
import customFieldType from "@/types/api-orisis/enums/customFieldType";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
import { customFieldTemplateType } from "@/types/api-orisis/enums/enums";
import { InPlaceEditorComponent } from "@syncfusion/ej2-vue-inplace-editor";

export default {
  components: {
    EditCustomField,
    HeaderEditCustomField,
    SyncGrid: () => import("@/components/global/grid/Grid.vue"),
    "ejs-inplaceeditor": InPlaceEditorComponent,
  },
  props: {
    id: {
      default: 0,
    },
    archive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customFieldType,
      customFieldTemplateType,
      // Gestion du form principal
      name: "editCustomFieldTemplate",
      dateRangeDefault: null, //[new Date(dayjs().format("YYYY-MM-DD")).toISOString(), new Date(dayjs().format("YYYY-MM-DD")).toISOString()],
      dataForm: {
        id: 0,
        label: null,
        type: null,
        customFieldGroups: [],
      },
      dataOrigine: {},
      loading: true,
      dataCompany: {},
      dataCompanyLoading: true,
      key: 1,
      groupActive: 0,
      headerData: [],
      tagguerButton: false,
      allowGrouping: false,
      allowPaging: false,
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        titleAdd: "Nouveau champs",
        titleEdit: "Modifier le champs",
        titleField: "customField",
        mode: "Dialog",
        template: function () {
          return {
            template: EditCustomField,
          };
        },
      },

      formObj: "",
      options: {
        rules: {
          label: {
            required: [true, "Saisissez un nom de formulaire"],
          },
          type: {
            required: [true, "Sélectionnez un type de formulaire"],
          },
        },
      },

      optionCivility: [
        {
          label: "M. et Mme",
          value: ECivility.MonsieurMadame,
        },
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },

        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "userCollaboratorId",
      "isLoadingCustomFieldTemplate",
      "isCreatingCustomFieldTemplate",
      "isUpdatingCustomFieldTemplate",
      "institutionSelected",
    ]),
  },

  async created() {
    this.setHeaderData();
    if (this.id > 0) {
      if (this.archive) {
        this.getCustomFieldTemplateArchivedById({
          customFieldTemplateId: this.id,
        }).then((res) => {
          res.customFieldGroups.sort((a, b) =>
            a.position > b.position ? 1 : b.position > a.position ? -1 : 0
          );
          res.customFieldGroups.forEach((group) => {
            group.customFields.sort((a, b) =>
              a.position > b.position ? 1 : b.position > a.position ? -1 : 0
            );
            let customFields = group.customFields.map((elem) =>
              elem.typeField === 10
                ? { ...elem, defaultValue: elem.defaultValue.split(";") }
                : { ...elem }
            );
            group.customFields = customFields;
          });
          this.dataForm = res;
          this.dataOrigine = JSON.stringify(this.dataForm);
          this.key++;
          this.formObj = null;
          this.loading = false;
          this.$nextTick(() => {
            this.formObj = new FormValidator(
              "#formEditCustomFieldTemplate" + this.key,
              this.options
            );
          });
        });
      } else {
        this.getCustomFieldTemplateById({
          customFieldTemplateId: this.id,
        }).then((res) => {
          res.customFieldGroups.sort((a, b) =>
            a.position > b.position ? 1 : b.position > a.position ? -1 : 0
          );
          res.customFieldGroups.map((group) => {
            group.customFields.sort((a, b) =>
              a.position > b.position ? 1 : b.position > a.position ? -1 : 0
            );
            let customFields = group.customFields.map((elem) =>
              elem.typeField === 10
                ? { ...elem, defaultValue: elem.defaultValue.split(";") }
                : { ...elem }
            );
            group.customFields = customFields;
          });
          this.dataForm = res;
          this.dataOrigine = JSON.stringify(this.dataForm);
          this.key++;
          this.formObj = null;
          this.loading = false;
          this.$nextTick(() => {
            this.formObj = new FormValidator(
              "#formEditCustomFieldTemplate" + this.key,
              this.options
            );
          });
        });
      }
    } else {
      this.initializeForm();
    }
  },
  mounted() {
    if (this.$route.meta.mode == "single" && this.userCollaboratorId) {
      this.dataForm.collaboratorId = parseInt(this.userCollaboratorId);
    }
  },
  methods: {
    ...mapActions([
      "getCustomFieldTemplateArchivedById",
      "getCustomFieldTemplateById",
      "createCustomFieldTemplate",
      "updateCustomFieldTemplate",
      "archiveCustomFieldTemplates",
      "restoreCustomFieldTemplates",
      "deleteCustomFieldTemplates",
      "createCustomFieldGroup",
      "updateCustomFieldGroup",
      "deleteCustomFieldGroups",
      "createCustomField",
      "updateCustomField",
      "deleteCustomFields",
    ]),
    formatCurrency,
    setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: false,
        },
        {
          type: "number",
          field: "typeField",
          headerText: "Type de champs",
          allowFiltering: true,
          width: 140,
          maxWidth: 140,
          visible: true,
          showInColumnChooser: true,
          showFooterSum: false,
          template: "typeFieldTemplate",
          valueAccessor: "dropdown",
          defaultValue: 1,
        },
        {
          type: "string",
          field: "label",
          headerText: "Libellé",
          allowFiltering: true,
          visible: true,
          showInColumnChooser: true,
          showFooterSum: false,
          // validationRules: { required: true, minLength: 2 },
        },
        {
          type: "string",
          field: "description",
          headerText: "Description",
          allowFiltering: true,
          visible: true,
          showInColumnChooser: true,
          showFooterSum: false,
        },
        {
          type: "string",
          field: "options",
          headerText: "Options (séparées par ';')",
          allowFiltering: true,
          showInColumnChooser: true,
          visible: true,
          showInColumnChooser: true,
          showFooterSum: false,
          // validationRules: { required: true, minLength: 2 },
        },
        {
          type: "string",
          field: "parameters",
          headerText: "Paramètres",
          allowFiltering: true,
          visible: true,
          showInColumnChooser: true,
          showFooterSum: false,
        },
        {
          type: "string",
          field: "defaultValue",
          template: "customFieldTemplate",
          headerText: "Valeur par défaut",
          allowFiltering: true,
          showInColumnChooser: true,
          showFooterSum: false,
          visible: true,
        },
        {
          type: "number",
          field: "id",
          headerText: "ID",
          foreignKeyField: "id",
          width: 90,
          maxWidth: 90,
          allowFiltering: true,
          allowEditing: false,
          showInColumnChooser: true,
          defaultValue: 0,
          visible: false,
        },
        {
          field: "action",
          headerText: "Action",
          width: 70,
          template: "deleteTemplate",
        },
      ];
      this.headerLoaded = true;
    },
    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        //if args.data.options start or end with ';', remove it
        if (args.data.options) {
          if (args.data.options.startsWith(";")) {
            args.data.options = args.data.options.substring(1);
          }
          if (args.data.options.endsWith(";")) {
            args.data.options = args.data.options.substring(
              0,
              args.data.options.length - 1
            );
          }
        }
        this.dataForm.customFieldGroups[this.groupActive].customFields.push(
          args.data
        );
      }
      if (args.requestType === "save" && args.action === "edit") {
        if (args.data.options) {
          if (args.data.options.startsWith(";")) {
            args.data.options = args.data.options.substring(1);
          }
          if (args.data.options.endsWith(";")) {
            args.data.options = args.data.options.substring(
              0,
              args.data.options.length - 1
            );
          }
        }
        if (args.rowData.typeField === 11) {
          if (args.rowData.defaultValue === true) {
            args.rowData.defaultValue = "true";
          }
        }
        this.dataForm.customFieldGroups[this.groupActive].customFields.splice(
          args.rowIndex,
          1,
          args.data
        );
      }
      if (args.requestType === "delete") {
        this.deleteCustomFields({
          customFieldIds: args.data.map((elem) => elem.id),
        });
      }
    },
    async deleteButtonClicked(args) {
      this.dataForm.customFieldGroups[this.groupActive].customFields.splice(
        args.rowIndex,
        1
      );
      this.$refs[
        "overviewgrid" + this.groupActive
      ][0].$refs.overviewgrid.closeEdit();
      if (args.rowData.id > 0) {
        // On supprime de la base le champs custom
        this.deleteCustomFields({
          customFieldIds: [args.rowData.id],
        });
      }
      this.$refs[
        "overviewgrid" + this.groupActive
      ][0].$refs.overviewgrid.refresh();
    },
    initializeForm() {
      this.loading = true;
      this.dataForm = {
        id: 0,
        label: "",
        customFieldGroups: [
          {
            id: 0,
            label: "Groupe Sans Nom",
            color: "",
            position: 0,
            customFieldTemplateId: this.id,
            customFields: [],
          },
        ],
      };
      this.dataOrigine = JSON.stringify(this.dataForm);

      this.key++;
      this.formObj = null;
      this.loading = false;
      this.$nextTick(() => {
        this.formObj = new FormValidator(
          "#formEditCustomFieldTemplate" + this.key,
          this.options
        );
      });
    },
    addGroup(index) {
      this.dataForm.customFieldGroups.splice(index + 1, 0, {
        id: 0,
        label: "Groupe " + (index + 1),
        color: "",
        position: index + 1,
        customFieldTemplateId: this.id,
        customFields: [],
      });
    },
    async deleteGroup(index, group) {
      if (group.id > 0) {
        this.deleteCustomFieldGroups({ customFieldGroupIds: [group.id] });
      }
      if (group.customFields && group.customFields.length > 0) {
        for (
          let indexField = 0;
          indexField < group.customFields.length;
          indexField++
        ) {
          const field = group.customFields[indexField];
          if (field.id > 0) {
            await this.deleteCustomField({
              customFieldIds: [field.id],
            });
          }
        }
        this.dataForm.customFieldGroups.splice(index, 1);
      } else {
        this.dataForm.customFieldGroups.splice(index, 1);
      }
    },
    addField(indexGroup) {
      this.groupActive = indexGroup;
      this.$refs["overviewgrid" + indexGroup][0].$refs.overviewgrid.addRecord();
    },
    async deleteCustomFieldTemplateLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Attention ! Vous ne pourrez plus récupérer ce formulaire de champs personnalisés.",
          {
            title:
              'Êtes-vous sûr de vouloir supprimer définitivement le formulaire "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Supprimer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await this.deleteCustomFieldTemplates({
              customFieldTemplateIds: [id],
            });
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },
    async archiveCustomFieldTemplateLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title:
              'Êtes-vous sûr de vouloir archiver le formulaire "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await this.archiveCustomFieldTemplates({
              customFieldTemplateIds: [id],
            });
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },
    async restoreCustomFieldTemplateLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title:
              'Êtes-vous sûr de vouloir restaurer le formulaire "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await this.restoreCustomFieldTemplates({
              customFieldTemplateIds: [id],
            });
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },
    async confirm(andNew = 0) {
      let formStatus = this.formObj.validate();
      if (formStatus) {
        //this.formObj.element.reset();
        // this.$refs.dialogObj.show();
        this.$store.commit("SET_IS_CREATING_CUSTOM_FIELD_TEMPLATE", true);
        if (this.dataForm.id) {
          await this.updateCustomFieldTemplate({
            customFieldTemplate: this.dataForm,
          });
          for (
            let indexGroup = 0;
            indexGroup < this.dataForm.customFieldGroups.length;
            indexGroup++
          ) {
            const group = {
              ...this.dataForm.customFieldGroups[indexGroup],
              customFields: this.dataForm.customFieldGroups[
                indexGroup
              ].customFields.map((elem) =>
                elem.typeField === 10
                  ? { ...elem, defaultValue: elem.defaultValue.join(";") }
                  : { ...elem }
              ),
            };
            if (group.id == 0 || !group.id) {
              this.createCustomFieldGroup({
                customFieldGroup: {
                  ...group,
                  position: indexGroup,
                  customFieldTemplateId: this.dataForm.id,
                },
              }).then((resCustomFieldGroup) => {
                for (
                  let indexField = 0;
                  indexField < group.customFields.length;
                  indexField++
                ) {
                  const field = group.customFields[indexField];
                  this.createCustomField({
                    customField: {
                      ...field,
                      position: indexField,
                      customFieldGroupId: resCustomFieldGroup.id,
                      code: "CF" + indexField + "G" + resCustomFieldGroup.id,
                    },
                  });
                }
              });
            } else {
              this.updateCustomFieldGroup({
                customFieldGroup: {
                  ...group,
                  position: indexGroup,
                },
              }).then((resCustomFieldGroup) => {
                for (
                  let indexField = 0;
                  indexField < group.customFields.length;
                  indexField++
                ) {
                  const field = group.customFields[indexField];
                  if (field.id == 0 || !field.id) {
                    this.createCustomField({
                      customField: {
                        ...field,
                        position: indexField,
                        customFieldGroupId: group.id,
                        code: "CF" + indexField + "G" + group.id,
                      },
                    });
                  } else {
                    this.updateCustomField({
                      customField: {
                        ...field,
                        position: indexField,
                        customFieldGroupId: group.id,
                        code: "CF" + indexField + "G" + group.id,
                      },
                    });
                  }
                }
              });
            }
          }
        } else {
          await this.createCustomFieldTemplate({
            customFieldTemplate: {
              ...this.dataForm,
              institutionId: this.institutionSelected.id,
            },
          }).then((resCustomFieldTemplate) => {
            for (
              let indexGroup = 0;
              indexGroup < this.dataForm.customFieldGroups.length;
              indexGroup++
            ) {
              const group = {
                ...this.dataForm.customFieldGroups[indexGroup],
                customFields: this.dataForm.customFieldGroups[
                  indexGroup
                ].customFields.map((elem) =>
                  elem.typeField === 10
                    ? { ...elem, defaultValue: elem.defaultValue.join(";") }
                    : { ...elem }
                ),
              };
              this.createCustomFieldGroup({
                customFieldGroup: {
                  ...group,
                  position: indexGroup,
                  customFieldTemplateId: resCustomFieldTemplate.id,
                },
              }).then((resCustomFieldGroup) => {
                for (
                  let indexField = 0;
                  indexField < group.customFields.length;
                  indexField++
                ) {
                  const field = group.customFields[indexField];
                  this.createCustomField({
                    customField: {
                      ...field,
                      position: indexField,
                      customFieldGroupId: resCustomFieldGroup.id,
                      code: "CF" + indexField + "G" + resCustomFieldGroup.id,
                    },
                  });
                }
              });
            }
          });
        }
        this.$store.commit("SET_IS_CREATING_CUSTOM_FIELD_TEMPLATE", false);
        this.$tabs.close({ to: "/parameters/customFieldTemplates" });
      }
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.dataForm)) {
        if (this.$route.params.routeOrigine) {
          this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
        } else {
          this.$tabs.close({ to: "/parameters/customFieldTemplates" });
        }
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
  },
};
</script>
<style lang="scss">
.e-chip-list .e-chip:last-child {
  margin-right: 0px !important;
}
h4 .e-editable-value {
  border-bottom: 1px dashed #212529;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  font-size: 1.286rem !important;
  color: #5e5873 !important;
}
h4 .e-component-group {
  margin-bottom: 0px !important;
}
</style>
