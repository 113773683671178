<template lang="pug">
div
    ejs-button(:isPrimary="true" locale='fr' v-on:click.native="buttonValid" ) Enregistrer
    ejs-button(locale='fr' v-on:click.native="buttonClose") Fermer
</template>
<script>
export default {
    data() {
        return {
            data: {}
        }
    },
    created() {
    },
    methods: {
        buttonClose(){
            this.$emit('closeDialog')
        },
        buttonValid(){

        }
    },
}
</script>
<style lang="scss">
    
</style>